import styled from "styled-components"

import { PageHelmet } from "src/ui/Helmet/PageHelmet"
import { DefaultBox, TDefaultBoxWidth } from "src/ui/Layout/DefaultBox"
import { ITitleBarProps, Titlebar } from "src/ui/Layout/Titlebar"
import { isString } from "src/utils/genericUtil"

/**
 * Layout component for app 'main views'.
 *
 * The `helmet` prop is only necessary if either of the following are true:
 * 1. The title prop is not a string type and you want to give the view a
 *    non-default page title.
 * 2. You want the title and helmet (html page title) to be different.
 */
export function MainView({
  children,
  size = "large",
  banner,
  helmet,
  title,
  titleBarProps,
  breadcrumbs,
}: {
  title: React.ReactNode
  children: React.ReactNode
  helmet?: string
  size?: TDefaultBoxWidth
  banner?: React.ReactNode
  breadcrumbs?: React.ReactNode
  titleBarProps?: Omit<ITitleBarProps, "size" | "title">
}) {
  const mHelmet = !!helmet ? helmet : isString(title) ? title : undefined

  return (
    <MainViewBox>
      <PageHelmet helmet={mHelmet} />

      {banner}

      <Titlebar
        title={title}
        size={size}
        breadcrumbs={breadcrumbs}
        {...titleBarProps}
      />

      <DefaultBox size={size}>{children}</DefaultBox>
    </MainViewBox>
  )
}

const MainViewBox = styled.div``
